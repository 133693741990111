import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { menu } from 'app/menu/menu';

import { AuthenticationService } from 'app/auth/service';
import _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService) {}

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this._authenticationService.currentUserValue;

    if (currentUser) {
      const customMenu =  _.cloneDeep(menu).filter(el=>{
        el.children = el.children.filter(it=>currentUser.role.permissions?.find(el=>el.name === it.id))
        return el.children.length;
      });
      console.log(Object.values(route.data)[0]);
      if(customMenu && !this._authenticationService.checkPermission(Object.values(route.data)[0])){
        
        this._router.navigate([customMenu[0].children[0].url])

      }
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
        // role not authorised so redirect to not-authorized page
        this._router.navigate(['/pages/miscellaneous/not-authorized']);
        return false;
      }

      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this._router.navigate(['/pages/authentication/login-v2'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
